import { ChakraProvider } from '@chakra-ui/react';
import { Provider } from 'mobx-react';
import { DefaultSeo } from 'next-seo';
import { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { Fragment, useCallback, useEffect } from 'react';
import { SWRConfig } from 'swr';

import { GoogleLoginHandler } from '@/components/GoogleLoginHandler';
import { GoogleTagManager } from '@/components/GoogleTagManager';
import { LibLynxCounter, LibLynxCounterInit } from '@/components/LibLynx';
import { BugsnagError } from '@/components/ui/shared/overlays/BugsnagError/BugsnagError';
import { AccessiBeWidget } from '@/components/ui/shared/utilities/AccessiBeWidget/AccessiBeWidget';
import { BugsnagErrorBoundary } from '@/components/ui/shared/utilities/BugsnagErrorBoundary/BugsnagErrorBoundary';
import { AnalyticsProvider, useAnalytics } from '@/context/Analytics';
import { useConstant } from '@/hooks/useConstant';
import { datxMiddleware } from '@/lib/datx-jsonapi-react';
import { GlobalStyles } from '@/styles/global';
import { chakraDefault } from '@/styles/themes/default';
import { generateStore } from '@/utils/generateStore';

import SEO from '../next-seo.config';

import 'cropperjs/dist/cropper.min.css';
import 'focus-visible/dist/focus-visible';
import 'nprogress/nprogress.css';
import '../i18n';
import { configure } from 'mobx';

const TopProgressBar = dynamic(
  async () => {
    return import('@/components/TopProgressBar');
  },
  { ssr: false }
);

const customIdentifyPathnames = [
  '/events/[eventId]/reception',
  '/events/[eventId]/sessions/[sessionId]/lecture/[lectureId]',
  '/events/[eventId]/posters/[sessionId]/poster/[posterId]',
];

configure({ isolateGlobalState: true });

const AnalyticsRouteTracker = () => {
  const router = useRouter();
  const analytics = useAnalytics();

  const onRouteChangeComplete = useCallback(
    (route: string) => {
      if (route.indexOf('/events/') === -1) {
        analytics.page(route);
      }

      // on all pages except for the ones in this array we do this anonymous identify
      if (!customIdentifyPathnames.includes(router.pathname)) {
        analytics.identify();
      }
    },
    [router, analytics]
  );

  useEffect(() => {
    router.events.on('routeChangeComplete', onRouteChangeComplete);

    return () => {
      router.events.off('routeChangeComplete', onRouteChangeComplete);
    };
  }, [router, onRouteChangeComplete]);

  return null;
};

const UnderlineApp = ({ Component, pageProps }: AppProps<{ snapshot: string }>) => {
  const store = useConstant(() => {
    if (pageProps.snapshot) {
      // this is hydration phase
      return generateStore({ snapshot: pageProps.snapshot });
    }

    // this is server side phase
    return generateStore();
  });

  return (
    <Fragment>
      <DefaultSeo {...SEO} />
      <AnalyticsProvider writeKey={process.env.NEXT_PUBLIC_SEGMENT_API_KEY}>
        <Provider store={store}>
          <SWRConfig value={{ fetcher: store?.fetcher, use: [datxMiddleware] }}>
            <ChakraProvider theme={chakraDefault} resetCSS>
              <LibLynxCounter />
              <LibLynxCounterInit />
              <GoogleTagManager />
              <GoogleLoginHandler />
              <AccessiBeWidget />
              <GlobalStyles />
              <AnalyticsRouteTracker />
              <BugsnagErrorBoundary FallbackComponent={BugsnagError}>
                <Component {...pageProps} />
                <TopProgressBar />
              </BugsnagErrorBoundary>
            </ChakraProvider>
          </SWRConfig>
        </Provider>
      </AnalyticsProvider>
    </Fragment>
  );
};

export default UnderlineApp;
