import { prop } from 'datx';

import { Track } from '@/resources/Track';

import { BaseModel } from './BaseModel';

// extract to enum
export enum ThinEventSessionType {
  PRE_RECORDED = 'pre_recorded',
  EXTERNAL = 'external',
  MAIN_STAGE = 'main_stage',
  DISCUSSION_ROOM = 'discussion_room',
}

export enum SessionLectureCardType {
  IMAGE_CARDS = 'image_card',
  TEXT_CARDS = 'text_cards',
}

export class ThinEventSession extends BaseModel {
  public static type = 'thin_event_sessions';

  @prop
  public type: ThinEventSessionType;

  @prop
  public ctaUrl: string;

  @prop
  public ctaLabel: string;

  @prop
  public name: string;

  @prop
  public startsAt: string;

  @prop
  public endsAt: string;

  @prop
  public url?: string;

  @prop
  public description: string;

  @prop
  public posterOnly: string;

  @prop
  public lecturesCount: number;

  @prop
  public lectureCardsType: SessionLectureCardType;

  @prop
  public venue: string;

  @prop
  displayLecturesWithTime: boolean;

  @prop
  public chair: string;

  @prop.toOne('tracks')
  public track: Track;
}
